<template>
  <div class="edit_left" :class="horizontal?'row':'column'">
    <div
      class="edit_num"
      :class="{'edit_num_check':activeIndex==index}"
      v-for="(item,index) in len"
      :key="index"
      @click="changeActive(index)"
    >
      <Icon
        class="remove"
        type="md-remove"
        v-if="modifiable&&index>0"
        @click.stop="removeObj(index)"
      />
      <span>{{index+1}}</span>
    </div>
    <Icon class="edit_num" type="md-add" v-if="modifiable" @click="addObj" />
    <Modal v-model="removeModal" :title="removeTitle" @on-ok="removeComfirm"></Modal>
  </div>
</template>
<script>
export default {
  props: {
    activeIndex: {
      type: Number,
      default: 0
    },
    len: {
      required: true
    },
    modifiable: {
      default: true
    },
    isEmptyFn: {
      // 删除时用于判断内容是否为空，空内容的可以直接删除，发送 clickRemove 事件
      default() {
        return ind => {
          console.warn(
            "Please offer a function to the EditorSider, in order to judge if the content specified by the index is empty or not"
          );
          return false;
        };
      }
    },
    horizontal: {
      default: false
    }
  },
  data() {
    return {
      removeTitle: "", // 删除提示
      removeModal: false, // 控制删除编辑框
      removeIndex: ""
    };
  },
  methods: {
    changeActive(index) {
      this.$emit("update:activeIndex", index);
    },
    //删除一个编辑框
    removeObj(ind) {
      if (this.isEmptyFn(ind)) {
        this.$emit("clickRemove", ind);
        return;
      }

      this.removeModal = true;
      this.removeTitle = `是否删除第${ind + 1}个输入框？`;
      this.removeIndex = ind;
    },
    // 确认删除一个编辑框
    removeComfirm(index) {
      this.$emit("clickRemove", this.removeIndex);
    },
    //新增一个编辑框
    addObj() {
      this.$emit("clickAdd");
    }
  }
};
</script>
<style scoped>
.edit_left {
  display: flex;
  align-items: center;
}
.column {
  flex-direction: column;
}
.row {
  flex-direction: row;
}
.edit_num {
  width: 2.5em;
  height: 2.5em;
  border: 1px solid rgb(170, 170, 170);
  text-align: center;
  line-height: 2.5em;
  position: relative;
}
.column .edit_num:not(:first-child) {
  border-top-width: 0;
}
.row .edit_num:not(:first-child) {
  border-left-width: 0;
}
.edit_num:not(.edit_num_check):hover {
  background: rgb(26, 179, 41);
  color: white;
  cursor: pointer;
}
.edit_num_check {
  background: rgb(3, 139, 17);
  color: white;
}
.remove {
  position: absolute;
  height: 0.75em;
  line-height: 0.75em;
  text-align: left;
  width: 0.75em;
  margin-left: 0.75em;
  background: rgb(182, 59, 59);
  color: #fff;
  cursor: pointer;
}
.remove:hover {
  background-color: rgb(201, 93, 93);
}
</style>